import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfig } from '../../AppConfig';
import { DEFAULT_LOCALE } from '../../utils/url';
import { APP_CONFIG } from '../injectionTokens';

@Injectable()
export class HttpService {
    protected baseUrl: string;

    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        private router: Router,
        private oidcSecurityService: OidcSecurityService,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
    ) {
        this.baseUrl = this.appConfig.servegoApi.rootUrl;
    }

    public get(url: string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http.get(this.baseUrl + url, httpOptions).pipe(catchError(this.handleError()));
    }

    public post(url: string, post: any): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http.post(this.baseUrl + url, post, httpOptions).pipe(catchError(this.handleError()));
    }

    public put(url: string, putData: any): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http.put(this.baseUrl + url, putData, httpOptions).pipe(catchError(this.handleError()));
    }

    public patch(url: string, post: any): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http.patch(this.baseUrl + url, post, httpOptions).pipe(catchError(this.handleError()));
    }

    public delete(url: string): Observable<any> {
        const httpOptions = this.getHttpOptions();
        return this.http.delete(this.baseUrl + url, httpOptions).pipe(catchError(this.handleError()));
    }

    public getDownload(url: string): Observable<any> {
        const httpOptions: any = this.getHttpOptions();
        httpOptions.headers.set('Accept', 'application/pdf');
        httpOptions.responseType = 'blob';
        return this.http.get(this.baseUrl + url, httpOptions).pipe(catchError(this.handleError()));
    }

    private getHttpOptions(): { headers: HttpHeaders } {
        const headers = {
            Authorization: `Bearer ${this.getAccessTokenFromLocalStorage()}`,
            'Accept-Language': this.translateService.currentLang || DEFAULT_LOCALE,
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        return {
            headers: new HttpHeaders(headers),
        };
    }

    /**
     * Unfortunately, subscribing to this.oidcSecurityService.getAccessToken() does nothing because it does not emit when
     * the access token is renewed. Therefore, we are forced to get it manually from the localStorage
     */
    private getAccessTokenFromLocalStorage(): string {
        const strObj = localStorage.getItem('0-' + this.appConfig.oauth2.clientId);
        if (!strObj) {
            return '';
        }
        const parsedObj = JSON.parse(strObj) as {
            authnResult: { access_token: string };
        };
        return parsedObj.authnResult.access_token;
    }

    private handleError<T>() {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            switch (error.status) {
                case 401:
                    this.oidcSecurityService.logoffAndRevokeTokens().subscribe();
                    break;
                case 403:
                    this.router.navigate(['/unauthorized']);
                    break;
                default:
                    console.warn(error.message);
            }
            // Let the app keep running by returning an empty result.
            throw error;
        };
    }
}
