import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AppConfig } from '../../AppConfig';
import { APP_CONFIG } from '../injectionTokens';
import { HttpService } from './http.service';

@Injectable()
export class BobHttpService extends HttpService {
    constructor(
        http: HttpClient,
        translateService: TranslateService,
        router: Router,
        oidcSecurityService: OidcSecurityService,
        @Inject(APP_CONFIG) appConfig: AppConfig,
    ) {
        super(http, translateService, router, oidcSecurityService, appConfig);
        this.baseUrl = appConfig.bobApi.url;
    }
}
