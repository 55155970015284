import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { GatewayId } from '../../../site-detail/models/Gateway';
import { ToolboxActions } from '../actions/toolbox';

export type ToolboxState = GatewayId[];

export const TOOLBOX_INITIAL_STATE: ToolboxState = [];

export default createReducer(
    TOOLBOX_INITIAL_STATE,

    on(ToolboxActions.loaded, (state, { toolboxes }): ToolboxState => {
        if (toolboxes == null) {
            return state;
        }
        return [...toolboxes];
    }),
);

export const getToolboxState = createFeatureSelector<ToolboxState>('toolbox');
