import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';
import { NavigationService } from '../services/NavigationService';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private oidcSecurityService: OidcSecurityService,
        private navigationService: NavigationService,
    ) {}

    async canActivate(): Promise<boolean> {
        const { isAuthenticated } = await firstValueFrom(this.oidcSecurityService.checkAuth());
        if (!isAuthenticated) {
            this.login();
            return false;
        }

        this.redirectAfterAuthentication();
        return true;
    }

    private login() {
        this.oidcSecurityService.setState(btoa(location.href)).subscribe(() => {
            this.oidcSecurityService.authorize();
        });
    }

    private redirectAfterAuthentication() {
        this.oidcSecurityService.getState().subscribe((encodedRedirectionUrl) => {
            if (encodedRedirectionUrl) {
                this.removeRedirectionUrlFromState();
                this.navigationService.gotoUrl(atob(encodedRedirectionUrl));
            }
        });
    }

    private removeRedirectionUrlFromState() {
        this.oidcSecurityService.setState(null).subscribe();
    }
}
