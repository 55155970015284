import { createSelector } from '@ngrx/store';
import { SiteId } from '../../../site-detail/models/Site';
import { getSiteById } from '../../../site-detail/store/selectors/site';
import { getToolboxState, ToolboxState } from '../reducers/toolbox';

export const getToolboxes = createSelector(getToolboxState, (state: ToolboxState) => state);

export const isToolbox = (siteId: SiteId) =>
    createSelector(getSiteById(siteId), getToolboxes, (site, toolboxes): boolean => {
        if (!site || site.setupGateways.length === 0) {
            return false;
        }
        return site.setupGateways.some((gateway) => toolboxes.includes(gateway));
    });
