import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
    selector: 'app-admin-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class UnAuthorizedComponent {
    constructor(private oidcSecurityService: OidcSecurityService) {}

    onLeave() {
        this.oidcSecurityService.logoffAndRevokeTokens().subscribe();
    }
}
