import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { OnlyVisibleForRolesDirective } from '../admin/directives/only-visible-for-roles.directive';
import { UnAuthorizedComponent } from '../core/components/unauthorized/unauthorized.component';
import { DeviceIconComponent } from '../site-detail/components/devices/device-icon/device-icon.component';
import { SiteIconComponent } from '../site-detail/components/icon/site-icon.component';
import { VideoCallToasterItemComponent } from '../video-conference/components/video-call-toaster-item/video-call-toaster-item.component';
import { ModalConfirmDeleteComponent } from './components/modal/modal-confirm-delete/modal-confirm-delete.component';
import { AccessGrantedItemComponent } from './components/toaster/access-granted-item/access-granted-item.component';
import { DeviceRefreshStateFailedItemComponent } from './components/toaster/device-refresh-state-failed-item/device-refresh-state-failed-item.component';
import { DeviceUpdateItemComponent } from './components/toaster/device-update-item/device-update-item.component';
import { ErrorItemComponent } from './components/toaster/error/error-item.component';
import { MailReceivedItemComponent } from './components/toaster/mail-received-item/mail-received-item.component';
import { MailSentItemComponent } from './components/toaster/mail-sent-item/mail-sent-item.component';
import { ToasterComponent } from './components/toaster/root/toaster.component';
import { ServegoChangedComponent } from './components/toaster/servego-changed-item/servego-changed-item.component';
import { SiteDeviceErrorItemComponent } from './components/toaster/site-device-error-item/site-device-error-item.component';
import { ToasterSuccessComponent } from './components/toaster/toaster-success/toaster-success.component';
import { LoaderComponent } from './loader/loader.component';
import { FormatPipe } from './pipes/format.pipe';

@NgModule({
    imports: [
        DeviceIconComponent,
        CommonModule,
        FormsModule,
        TranslateModule.forChild({ extend: true }),
        MatCardModule,
        MatTabsModule,
        MatListModule,
        MatCheckboxModule,
        MatGridListModule,
        MatTableModule,
        MatIconModule,
        MatSliderModule,
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatDialogModule,
        SiteIconComponent,
        ToasterSuccessComponent,
        ToasterComponent,
        DeviceUpdateItemComponent,
        AccessGrantedItemComponent,
        VideoCallToasterItemComponent,
        MailSentItemComponent,
        DeviceRefreshStateFailedItemComponent,
        ServegoChangedComponent,
        UnAuthorizedComponent,
        SiteDeviceErrorItemComponent,
        ErrorItemComponent,
        ModalConfirmDeleteComponent,
        OnlyVisibleForRolesDirective,
        MailReceivedItemComponent,
        LoaderComponent,
        FormatPipe,
    ],
    exports: [
        CommonModule,
        MatCardModule,
        MatTabsModule,
        MatListModule,
        MatCheckboxModule,
        MatGridListModule,
        MatTableModule,
        MatIconModule,
        MatSliderModule,
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        ToasterSuccessComponent,
        ToasterComponent,
        DeviceUpdateItemComponent,
        AccessGrantedItemComponent,
        VideoCallToasterItemComponent,
        MailSentItemComponent,
        DeviceRefreshStateFailedItemComponent,
        ServegoChangedComponent,
        UnAuthorizedComponent,
        SiteDeviceErrorItemComponent,
        ErrorItemComponent,
        ModalConfirmDeleteComponent,
        OnlyVisibleForRolesDirective,
        MailReceivedItemComponent,
        LoaderComponent,
        DeviceIconComponent,
        SiteIconComponent,
        FormatPipe,
    ],
})
export class SharedModule {}
