import { createSelector } from '@ngrx/store';
import { AlertSeverity } from '../../../alerts/models/Alert';
import { getAlertsSeverityBySites } from '../../../alerts/store/selectors/alerts';
import { User } from '../../../shared/models/User';
import { getUserLoginState } from '../../../shared/store/selectors/login';
import { Site, SiteId } from '../../../site-detail/models/Site';
import { getSiteById } from '../../../site-detail/store/selectors/site';
import { isToolbox } from '../../../toolbox/store/selectors/toolbox';
import { toShortDate } from '../../../utils/date';
import { getSitesState, SitesState } from '../reducers/sites';

export const getSites = createSelector(getSitesState, ({ sites }: SitesState) => sites);

export interface SiteItemVM {
    site: Site;
    userLogin: User;
    clientName: string;
    date: string;
    alertsSeverity: AlertSeverity;
    tooltip: string | null;
    isToolbox: boolean;
    isDisabled: boolean;
}

export const getSiteItemVm = (siteId: SiteId) =>
    createSelector(
        getSiteById(siteId),
        getUserLoginState,
        getAlertsSeverityBySites,
        isToolbox(siteId),
        (site, userLogin, alertsSeverityBySites, isToolbox): SiteItemVM => {
            return {
                site,
                userLogin,
                clientName: getClientName(site, isToolbox),
                date: getDate(site, isToolbox),
                alertsSeverity: alertsSeverityBySites[siteId] ?? null,
                tooltip: site ? getSiteItemTooltip(site, isToolbox) : '',
                isToolbox,
                isDisabled: !site?.isServego || isToolbox,
            };
        },
    );

function getClientName(site: Site, isToolbox: boolean): string {
    if (!site || !site.owner) {
        return '';
    }
    const { firstName, lastName } = site.owner;
    return !isToolbox && lastName !== 'N/A'
        ? `${firstName} ${lastName}`
        : `${firstName} ${lastName} (${site.gatewayId})`;
}

function getDate(site: Site | undefined, isToolbox: boolean): string {
    return site && site.activateDate && !isToolbox ? toShortDate(site.activateDate) : '';
}

function getSiteItemTooltip(site: Site, isToolbox: boolean): string | null {
    if (!site.isServego) {
        return 'ACTIVATE_SERVEGO_TEXT';
    }
    if (isToolbox) {
        return 'TOOL_SITE_DESCRIPTION';
    }
    return null;
}
