import { GatewayId } from '../site-detail/models/Gateway';
import { Owner, SetupId, Site, SiteId } from '../site-detail/models/Site';
import { stringDateToUnix } from './date';

export interface ApiSite {
    id: SiteId;
    setup_id: SetupId;
    setup_gateways: GatewayId[];
    servego_activated: number;
    activation_date: string;
    servego_activation_date: string;
    activation_status: string;
    favorite: number;
    external_gateway_id: GatewayId;
    owner: Owner;
}

export function buildSiteFromApiResult(apiResult: ApiSite): Site {
    const site: Site = {
        id: apiResult.id,
        setupId: apiResult.setup_id,
        activateDate: stringDateToUnix(apiResult.activation_date),
        gatewayId: apiResult.external_gateway_id,
        servegoActivateDate: apiResult.servego_activation_date
            ? stringDateToUnix(apiResult.servego_activation_date)
            : null,
        favorite: apiResult.favorite === 1,
        isServego: apiResult.servego_activated === 1,
        activation_status: apiResult.activation_status,
        setupGateways: apiResult.setup_gateways,
        owner: apiResult.owner,
    };

    return site;
}
