export class NavigationService {
    gotoUrl(url: string) {
        if (url !== location.href) {
            location.assign(url);
        }
    }

    getUrl() {
        return location.href;
    }
}
