import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { AppActions } from '../../../core/store/actions/app';
import { LinkRequestsService } from '../api/link-requests.service';

@Injectable()
export class LinkRequestEffects {
    loadLinkRequestsAfterInitialization$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppActions.initialized),
                switchMap(() => this.linkRequestsService.getLinkRequests()),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private linkRequestsService: LinkRequestsService,
    ) {}
}
