import { createSelector } from '@ngrx/store';
import { Alert } from '../../../alerts/models/Alert';
import { getPendingAlerts } from '../../../alerts/store/selectors/alerts';
import { getUserEnvLoadingState } from '../../../shared/store/selectors/user-env';
import { SiteId } from '../../../site-detail/models/Site';
import { getSites } from '../../../site-list/store/selectors/site-list';
import { isToolbox } from '../../../toolbox/store/selectors/toolbox';
import { FavoriteSite } from '../../models/favorite-site';
import { FavoriteSitesState, getFavoriteSitesState } from '../reducers/favorite-site';

export const MAX_FAVORITES = 5;

export const getFavorites = createSelector(getFavoriteSitesState, (state: FavoriteSitesState) => {
    return state;
});

export const isMaxFavoritesReached = createSelector(
    getFavorites,
    (favorites: SiteId[]) => favorites.length >= MAX_FAVORITES,
);

export const selectFavoriteSiteIconVm = (siteId: SiteId) =>
    createSelector(
        getFavorites,
        isMaxFavoritesReached,
        isToolbox(siteId),
        (favorites: SiteId[], isMaxFavoritesReached: boolean, isToolbox: boolean) => ({
            isFavorite: favorites.includes(siteId),
            isMaxFavoritesReached,
            isToolbox,
        }),
    );

export const getFavoriteSites = createSelector(
    getFavorites,
    getSites,
    getPendingAlerts,
    (favorites, sites, alerts): FavoriteSite[] => {
        return favorites
            .filter((favoriteSiteId) => sites[favoriteSiteId])
            .map((favoriteSiteId) => {
                const site = sites[favoriteSiteId];
                const siteAlerts = alerts.filter((alert) => alert.id_site === favoriteSiteId);

                const favoriteSite: FavoriteSite = {
                    siteId: site.id,
                    gatewayId: site.gatewayId,
                    nbSetupGateways: site.setupGateways.length,
                    owner: {
                        firstName: site.owner.firstName,
                        lastName: site.owner.lastName,
                    },
                };

                const alert = getAlertWithHighSeverity(siteAlerts);
                if (alert) {
                    favoriteSite.alert = { severity: alert.severity, date: alert.date };
                }

                return favoriteSite;
            })
            .sort((siteA, siteB) => (getMostRelevantAlert(siteA.alert, siteB.alert) === siteA.alert ? -1 : 1));
    },
);

export interface FavoriteSitesVm {
    favoriteSites: FavoriteSite[];
    loading: boolean;
}

export const getFavoriteSitesVm = createSelector(
    getFavoriteSites,
    getUserEnvLoadingState,
    (favoriteSites, loading) => ({ favoriteSites, loading }),
);

function getAlertWithHighSeverity(alerts: Alert[]): Alert {
    return alerts?.reduce(
        (highest, current) => (getMostRelevantAlert(highest, current) === current ? current : highest),
        alerts?.[0],
    );
}

function getMostRelevantAlert(alertA: Partial<Alert>, alertB: Partial<Alert>) {
    if (alertA == null || alertB == null) {
        return alertA ?? alertB;
    }

    if (alertA.severity > alertB.severity) {
        return alertA;
    }

    if (alertA.severity === alertB.severity && alertA.date > alertB.date) {
        return alertA;
    }
    return alertB;
}
